export default {
    name: "WarrantSearch",
    created() {
        this.$nextTick(function () {
            this.getSelectorSearchData();
            this.getSearchData();
            this.printTable();
            let scrollLeft = $(".search-form-box").scrollLeft();
            if (scrollLeft > 0) {
                $(".f_left").click(function () {
                    scrollLeft = $(".search-form-box").scrollLeft();
                    $(".search-form-box").scrollLeft(scrollLeft - 10);
                });
            }
            $(".f_right").click(function () {
                scrollLeft = $(".search-form-box").scrollLeft();
                $(".search-form-box").scrollLeft(scrollLeft + 10);
            });
        });
    },
    mounted() {
        this.$nextTick(function () {
            this.selector();
            localStorage.removeItem("recordArr");
            $(".search-column-selection-box-h-c").prop("checked", true);
            $(".search-column-selection-box-c-b")
                .find('input[type="checkbox"]')
                .prop("checked", true);
        });
    },
    data() {
        return {
            tagArr: [],
            dataObj: {
                underlyingNanme: ["all"],
                issuerName: ["RHB"],
                sortBy: "wcode",
                sortOrder: "asc",
                estimate: true
            },
            underlyingsData: null,
            iFInFo: false,
            mouse: false,
            MoreFilterT: false,
        };
    },
    methods: {
        // 重整 bid & ask
        info: function () {
            let timer1;
            this.getSearchData();
            $("#info").attr("disabled", true);
            clearTimeout(timer1);
            //设置一次性定时器
            timer1 = setTimeout(function () {
                $("#info").attr("disabled", false);
            }, 5000);
        },
        handleMouse: function (flag) {
            this.mouse = flag;
        },
        selector: function () {
            let _this = this;
            // 第一个 下拉菜单
            $(".search-triangle-one").click(function () {
                event.stopPropagation();
                if ($(".search-select-one-r").is(":visible") == true) {
                    $(".search-select-one-r").hide();
                } else {
                    $(".search-select-one-r").show();
                    // console.log(_this.dataObj.underlyingNanme);
                    _this.sharesDropDownList(_this.underlyingsData, true);
                }
            });
            $("html").click(function (event) {
                if ($(event.target).parents(".search-select-one-r").length == 0)
                    $(".search-select-one-r").hide();
            });

            // 第二个 下拉菜单
            $(".search-triangle-two").click(function () {
                event.stopPropagation();
                if ($(".search-select-two-r").is(":visible") == true) {
                    $(".search-select-two-r").hide();
                } else {
                    $(".search-select-two-r").show();
                }
            });
            $("html").click(function (event) {
                if ($(event.target).parents(".search-select-two-r").length == 0)
                    $(".search-select-two-r").hide();
            });

            // 第三个 下拉菜单
            var BT = true;
            $(".search-select-three").click(function () {
                _this.MoreFilterT = !_this.MoreFilterT;
                if (BT) {
                    $(".search-button-triangle").css({ transform: "rotateX(180deg)" });
                    $(".search-multiterm").show();
                    BT = !BT;
                } else {
                    $(".search-button-triangle").css({ transform: "rotateX(0deg)" });
                    $(".search-multiterm").hide();
                    BT = !BT;
                }
                _this.FormdynamicChange();
            });

            // More Filter 下 全选和单选
            //全局全选与单个的关系
            $(".search-column-selection-box-h-c").click(function () {
                var $checkboxs = $(".search-column-selection-box-c-b").find(
                    'input[type="checkbox"]'
                );
                if ($(this).is(":checked")) {
                    $checkboxs.prop("checked", true);
                } else {
                    $checkboxs.prop("checked", false);
                }
                _this.FormdynamicChange();
            });

            $(".search-column-selection-box-s").each(function () {
                $(this).click(function () {
                    if (
                        $(this)
                            .find("input")
                            .is(":checked")
                    ) {
                        //判断：所有单个是否勾选
                        var len = $(".search-column-selection-box-s").length;
                        var num = 0;
                        $(".search-column-selection-box-s").each(function () {
                            if (
                                $(this)
                                    .find("input")
                                    .is(":checked")
                            ) {
                                num++;
                            }
                        });
                        if (num == len) {
                            $(".search-column-selection-box-h-c").prop("checked", true);
                        }
                    } else {
                        //单个取消勾选，全局全选取消勾选
                        $(".search-column-selection-box-h-c").prop("checked", false);
                    }
                    _this.FormdynamicChange();
                });
            });
        },
        getSelectorSearchData: function () {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$js.webservice + "GetScreenerData",
                data: { token: "webkey", init: "1" },
                success: res => {
                    if (res.status == "SUCCESS") {
                        this.selectorAssignment(res.underlyings, res.issuers);
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        selectorAssignment: function (underlyings, rhb) {
            this.underlyingsData = underlyings;
            let then = this;
            // 初始化
            $(".search-select-one-text")
                .find(".name")
                .text("All Shares");
            $(".search-select-two-text")
                .find(".name")
                .text("RHB");
            this.sharesDropDownList(underlyings);
            this.seekShares(underlyings);
            $(".search-select-one-text")
                .find(".name")
                .val("All Shares");
            var rhbText = "";
            rhbText += '<div class="search-select-two-multiple">';
            rhbText +=
                '<input type="checkbox" value="checkbox" style="margin-right: 5%; transform:translateY(2px)" class="parent_check_two">';
            rhbText += "<span>All Issuers</span>";
            rhbText += "</div>";
            // rhbText += '<div class="search-select-two-img"><img src="image/Warrant_Search_RHB.png" alt="" width="100%"></div>'
            rhbText += '<div class="checkbox rhbbox_two">';
            for (var r = 0, max1 = rhb.length; r < max1; r++) {
                rhbText += '<div class="search-select-two-multiple">';
                rhbText +=
                    '<input type="checkbox" value="checkbox" style="margin-right: 5%; transform:translateY(2px)" class="son_check_two">';
                if (rhb[r] === "MACQ") {
                    rhbText += "<span>MACQUARIE</span >";
                } else {
                    rhbText += "<span>" + rhb[r].toUpperCase() + "</span >";
                }

                rhbText += "</div >";
            }
            rhbText += "</div>";
            $(".search-select-two-r").append(rhbText);
            // 初始化
            // 第一下拉菜单的全选和单选
            //全局全选与单个的关系
            if (
                $(".search-select-one-text")
                    .find(".name")
                    .val() === "All Shares"
            ) {
                $(".search-select-one-multiple input").prop("checked", true);
                then.dataObj.underlyingNanme = [];
                $.each(underlyings, function (index, value) {
                    then.dataObj.underlyingNanme.push(value.underlying_ticker);
                });
                this.iFInFo = true;
            }
            this.sharesDropDownListAbility(underlyings);

            // 第二下拉菜单的全选和单选
            //全局全选与单个的关系
            $(".parent_check_two").click(function () {
                var $checkboxs = $(".rhbbox_two").find('input[type="checkbox"]');
                if ($(this).is(":checked")) {
                    $checkboxs.prop("checked", true);
                } else {
                    $checkboxs.prop("checked", false);
                }
            });
            var rLenTwo = $(".rhbbox_two").find(".search-select-two-multiple").length;
            for (var j = 0, max2 = rLenTwo; j < max2; j++) {
                if (
                    $(".rhbbox_two")
                        .find(".search-select-two-multiple span")
                        .eq(j)
                        .text() === "RHB"
                ) {
                    $(".rhbbox_two")
                        .find(".search-select-two-multiple input")
                        .eq(j)
                        .prop("checked", true);
                    then.dataObj.issuerName = ["RHB"];
                }
            }
            $(".son_check_two").each(function () {
                $(this).click(function () {
                    if ($(this).is(":checked")) {
                        //判断：所有单个是否勾选
                        var len = $(".son_check_two").length;
                        var num = 0;
                        $(".son_check_two").each(function () {
                            if ($(this).is(":checked")) {
                                num++;
                            }
                        });
                        if (num == len) {
                            $(".parent_check_two").prop("checked", true);
                        }
                        if (
                            $(this)
                                .next()
                                .text() === "MACQUARIE"
                        ) {
                            then.dataObj.issuerName.push("MACQ");
                        } else {
                            then.dataObj.issuerName.push(
                                $(this)
                                    .next()
                                    .text()
                            );
                            // if ($(this).next().text() !== 'RHB' && $(this).next().text() !== 'CIMB') {
                            //     then.dataObj.issuerName.push($(this).next().text().toLowerCase().slice(0, 1).toUpperCase() + $(this).next().text().toLowerCase().slice(1));
                            // } else {
                            //     then.dataObj.issuerName.push($(this).next().text())
                            // }
                        }
                        then.getSearchData();
                    } else {
                        //单个取消勾选，全局全选取消勾选
                        $(".parent_check_two").prop("checked", false);
                        let issuerText = $(this)
                            .next()
                            .text();
                        console.log(issuerText);
                        $.each(then.dataObj.issuerName, function (index, value) {
                            if (value === "MACQ") {
                                value = "MACQUARIE";
                            }
                            if (value === issuerText) {
                                then.dataObj.issuerName.splice(index, 1);
                            }
                        });
                        then.getSearchData();
                    }
                    if (then.dataObj.issuerName === rhb.length) {
                        $(".search-select-two-text")
                            .find(".name")
                            .text("All Issuers");
                    } else {
                        $(".search-select-two-text")
                            .find(".name")
                            .text(then.dataObj.issuerName.toString().toUpperCase());
                    }
                    if (then.dataObj.issuerName.length === 0) {
                        $(".search-select-two-text")
                            .find(".name")
                            .text(" ");
                    }
                });
            });
            $(".parent_check_two").change(function () {
                then.dataObj.issuerName = [];
                if ($(this).is(":checked")) {
                    $.each(rhb, function (index, value) {
                        then.dataObj.issuerName.push(value);
                    });
                    $(".search-select-two-text")
                        .find(".name")
                        .text("All Issuers");
                    then.getSearchData();
                } else {
                    $(".search-select-two-text")
                        .find(".name")
                        .text(" ");
                    then.getSearchData();
                }
            });
            this.selectSort();
        },
        // Shares下拉菜单
        sharesDropDownList: function (data, ifInput) {
            $(".search-select-one-r").empty();
            var underlyingsText = "";
            underlyingsText += '<div class="search-select-one-multiple">';
            underlyingsText += "<span>All Shares</span>";
            underlyingsText += "</div>";
            underlyingsText += '<div class="search-select-one-multiple">';
            underlyingsText += "<span>Hong Kong Underlying</span>";
            underlyingsText += "</div>";
            underlyingsText += '<div class="search-select-one-multiple">';
            underlyingsText += "<span>United States Underlying</span>";
            underlyingsText += "</div>";
            underlyingsText += '<div class="search-select-one-multiple">';
            underlyingsText += "<span>Malaysia Underlying</span>";
            underlyingsText += "</div>";
            underlyingsText += '<div class="checkbox rhbbox_one">';
            for (var u = 0, max = data.length; u < max; u++) {
                underlyingsText += '<div class="search-select-one-multiple">';
                underlyingsText +=
                    "<span>" +
                    "(" +
                    data[u].underlying_ticker +
                    ")" +
                    " (" +
                    data[u].underlying_code +
                    ") " +
                    data[u].underlying_name +
                    "</span >";
                underlyingsText += "</div >";
            }
            underlyingsText += "</div>";
            $(".search-select-one-r").append(underlyingsText);
            if (ifInput) {
                this.sharesDropDownListAbility(data, ifInput);
            }
        },
        // Shares下拉菜单功能
        sharesDropDownListAbility: function (data, ifInput) {
            let _this = this;
            $(".search-select-one-multiple").each(function () {
                $(this).click(function () {
                    if (
                        $(this)
                            .find("span")
                            .text() === "All Shares"
                    ) {
                        _this.dataObj.underlyingNanme = ["all"];
                        $(".search-select-one-text")
                            .find(".name")
                            .val(
                                $(this)
                                    .find("span")
                                    .text()
                            );
                    } else if (
                        $(this)
                            .find("span")
                            .text() === "Hong Kong Underlying"
                    ) {
                        _this.dataObj.underlyingNanme = ["allhk"];
                        $(".search-select-one-text")
                            .find(".name")
                            .val(
                                $(this)
                                    .find("span")
                                    .text()
                            );
                    } else if (
                        $(this)
                            .find("span")
                            .text() === "United States Underlying"
                    ) {
                        _this.dataObj.underlyingNanme = ["allus"];
                        $(".search-select-one-text")
                            .find(".name")
                            .val(
                                $(this)
                                    .find("span")
                                    .text()
                            );
                    } else if (
                        $(this)
                            .find("span")
                            .text() === "Malaysia Underlying"
                    ) {
                        _this.dataObj.underlyingNanme = ["allmy"];
                        $(".search-select-one-text")
                            .find(".name")
                            .val(
                                $(this)
                                    .find("span")
                                    .text()
                            );
                    } else {
                        _this.dataObj.underlyingNanme = [
                            $(this)
                                .find("span")
                                .text()
                                .split("(")[1]
                                .split(")")[0]
                        ];
                        $(".search-select-one-text")
                            .find(".name")
                            .val(
                                $(this)
                                    .find("span")
                                    .text()
                                    .split("(")[1]
                                    .split(")")[0]
                            );
                    }
                    $(".search-select-one-r").hide();
                    _this.getSearchData();
                });
            });
        },
        // Shares 搜索
        seekShares: function (data) {
            let _this = this;
            let NewData;
            let OidVal;
            $(".search-select-one-text")
                .find(".name")
                .bind("input propertychange", function () {
                    if (
                        _this.iFInFo &&
                        data.length === _this.dataObj.underlyingNanme.length
                    ) {
                        _this.dataObj.underlyingNanme = [];
                        _this.iFInFo = false;
                    }
                    NewData = data.filter(item => {
                        let liRic =
                            "(" +
                            item.underlying_ticker +
                            ")" +
                            " (" +
                            item.underlying_code +
                            ") " +
                            item.underlying_name;
                        return (
                            liRic.toLowerCase().indexOf(
                                $(this)
                                    .val()
                                    .replace(" ", "")
                                    .toLowerCase()
                            ) >= 0 ||
                            $(this)
                                .val()
                                .replace(" ", "")
                                .toLowerCase()
                                .indexOf(liRic.toLowerCase()) >= 0
                        );
                    });
                    _this.sharesDropDownList(NewData, true);
                    if (
                        $(this)
                            .val()
                            .replace(" ", "") === ""
                    ) {
                        $(".search-select-one-r").hide();
                    } else {
                        $(".search-select-one-r").show();
                    }
                });
            $(".search-select-one-text")
                .find(".name")
                .on("focus", function () {
                    OidVal = $(this).val();
                    $(this).val("");
                })
                .on("blur", function () {
                    $(this).val(OidVal);
                });
        },
        selectSort: function () {
            let then = this;
            var sortArr = [
                "wcode",
                "isr",
                "ub",
                "ep",
                "dwps",
                "mdate",
                "sen",
                "eg",
                "iv",
                "mdate",
                "dlt",
                "pre",
                "tt",
                "mn",
                "bv",
                "bp",
                "ap",
                "av",
                "tv",
                "pc"
            ];
            $(".search-form-name")
                .find(".asc")
                .on("click", function () {
                    var ascIndex = $(".search-form-name")
                        .find(".asc")
                        .index($(this));
                    console.log(sortArr[ascIndex]);
                    then.dataObj.sortBy = sortArr[ascIndex];
                    then.dataObj.sortOrder = "asc";
                    then.dataObj.estimate = false;
                    then.getSearchData();
                });
            $(".search-form-name")
                .find(".desc")
                .on("click", function () {
                    var descIndex = $(".search-form-name")
                        .find(".desc")
                        .index($(this));
                    then.dataObj.sortBy = sortArr[descIndex];
                    then.dataObj.sortOrder = "desc";
                    then.dataObj.estimate = false;
                    then.getSearchData();
                });
        },
        getSearchData: function () {
            let {
                underlyingNanme,
                issuerName,
                sortBy,
                sortOrder,
                estimate
            } = this.dataObj;
            $(".search-form-box tbody").empty();
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$js.webservice + "GetScreenerData",
                data: {
                    token: "webkey",
                    underlying: underlyingNanme.toString(),
                    type: "all",
                    issuer: issuerName.toString().replace(/,/g, ";"),
                    maturity: "all",
                    moneyness: "all",
                    effectiveGearing: "all",
                    expiry: "all",
                    sortBy: sortBy,
                    sortOrder: sortOrder
                },
                success: res => {
                    if (res.status == "SUCCESS") {
                        this.formData(res.data, estimate);
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        showForm: function () {
            let then = this;
            var $check_all = $("#check_all");
            var $default = $("#default");
            var $multiple_choice = $(".search-column-selection-box-s");
            var $form = $("#searchForm");
            var recordArr = [];
            var columuArr = [
                ["UndelyingBid", "h2_one", "c_one_1", true],
                ["ExercisePrice", "h2_one", "c_one_1", true],
                ["ExerciseRatio", "h2_one", "c_one_1", true],
                ["ExpiryDate", "h2_one", "c_one_1", true],
                ["Sensitivity", "h2_one", "c_one_1", true],
                ["EffectiveGearing", "h2_one", "c_one_1", true],
                ["ImpliedVolatility", "h2_one", "c_one_1", false],
                ["TimetoMaturity", "h2_one", "c_one_1", false],
                ["Delta", "h2_one", "c_one_1", false],
                ["Premium", "h2_one", "c_one_1", true],
                ["Theta", "h2_one", "c_one_1", false],
                ["Moneyness", "h2_one", "c_one_1", false],
                ["BidVolume", "h2_two", "c_two_1", true],
                ["BidPrice", "h2_two", "c_two_1", true],
                ["AskPrice", "h2_two", "c_two_1", true],
                ["AskVolume", "h2_two", "c_two_1", true],
                ["TradedVolume", "h2_two", "c_two_1", true],
                ["PriceChange", "h2_two", "c_two_1", true]
            ];
            if (localStorage.getItem("recordArr") !== null) {
                columuArr = JSON.parse(localStorage.getItem("recordArr"));
            }

            $(".search-column-selection-box-h-c").prop("checked", false);
            // 初始化
            $.each(columuArr, function (m, n) {
                switch (n[0]) {
                    case "ImpliedVolatility":
                        // 头部
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .removeClass(n[1]);
                        // 标题
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .hide();
                        // 内容
                        for (var i = 0; i < $(".form_c").length; i++) {
                            $(".form_c")
                                .eq(i)
                                .find("." + n[2])
                                .eq(m)
                                .hide();
                        }
                        //去钩
                        $multiple_choice
                            .find("input")
                            .eq(m)
                            .prop("checked", false);
                        break;
                    case "TimetoMaturity":
                        // 头部
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .removeClass(n[1]);
                        // 标题
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .hide();
                        // 内容
                        for (var i = 0; i < $(".form_c").length; i++) {
                            $(".form_c")
                                .eq(i)
                                .find("." + n[2])
                                .eq(m)
                                .hide();
                        }
                        //去钩
                        $multiple_choice
                            .find("input")
                            .eq(m)
                            .prop("checked", false);
                        break;
                    case "Delta":
                        // 头部
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .removeClass(n[1]);
                        // 标题
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .hide();
                        // 内容
                        for (var i = 0; i < $(".form_c").length; i++) {
                            $(".form_c")
                                .eq(i)
                                .find("." + n[2])
                                .eq(m)
                                .hide();
                        }
                        //去钩
                        $multiple_choice
                            .find("input")
                            .eq(m)
                            .prop("checked", false);
                        break;
                    // case "Premium":
                    //   // 头部
                    //   $form
                    //     .find(".h2 #" + n[1])
                    //     .eq(m)
                    //     .removeClass(n[1]);
                    //   // 标题
                    //   $form
                    //     .find(".h2 #" + n[1])
                    //     .eq(m)
                    //     .hide();
                    //   // 内容
                    //   for (var i = 0; i < $(".form_c").length; i++) {
                    //     $(".form_c")
                    //       .eq(i)
                    //       .find("." + n[2])
                    //       .eq(m)
                    //       .hide();
                    //   }
                    //   //去钩
                    //   $multiple_choice
                    //     .find("input")
                    //     .eq(m)
                    //     .prop("checked", false);
                    //   break;
                    case "Theta":
                        // 头部
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .removeClass(n[1]);
                        // 标题
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .hide();
                        // 内容
                        for (var i = 0; i < $(".form_c").length; i++) {
                            $(".form_c")
                                .eq(i)
                                .find("." + n[2])
                                .eq(m)
                                .hide();
                        }
                        //去钩
                        $multiple_choice
                            .find("input")
                            .eq(m)
                            .prop("checked", false);
                        break;
                    case "Moneyness":
                        // 头部
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .removeClass(n[1]);
                        // 标题
                        $form
                            .find(".h2 #" + n[1])
                            .eq(m)
                            .hide();
                        // 内容
                        for (var i = 0; i < $(".form_c").length; i++) {
                            $(".form_c")
                                .eq(i)
                                .find("." + n[2])
                                .eq(m)
                                .hide();
                        }
                        //去钩
                        $multiple_choice
                            .find("input")
                            .eq(m)
                            .prop("checked", false);
                        break;
                }
            });
            this.formHead();
            // 全选
            $check_all.click(function () {
                if ($(this).is(":checked")) {
                    // 头部
                    $form.find(".h2 #h2_one").addClass("h2_one");
                    $form.find(".h2 #h2_two").addClass("h2_two");
                    // 标题
                    $form.find(".h2 #h2_one").show();
                    $form.find(".h2 #h2_two").show();
                    // 内容
                    $form.find(".form_c .c_one_1").show();
                    $form.find(".form_c .c_two_1").show();
                    $form.find(".form_c .c_two_1:last-child").hide();
                    $.each(columuArr, function (index, value) {
                        value[3] = true;
                    });
                    localStorage.setItem("recordArr", JSON.stringify(columuArr));
                } else {
                    // 头部
                    $form.find(".h2 #h2_one").removeClass("h2_one");
                    $form.find(".h2 #h2_two").removeClass("h2_two");
                    // 标题
                    $form.find(".h2 #h2_one").hide();
                    $form.find(".h2 #h2_two").hide();
                    // 内容
                    $form.find(".form_c .c_one_1").hide();
                    $form.find(".form_c .c_two_1").hide();
                    $.each(columuArr, function (index, value) {
                        value[3] = false;
                    });
                    localStorage.setItem("recordArr", JSON.stringify(columuArr));
                }
                then.FormdynamicChange();
                then.formHead();
                if ($("#searchForm").outerWidth(true) > 1283) {
                    $(".search-form-box").css({ "overflow-x": "scroll" });
                } else {
                    $(".search-form-box").css({ "overflow-x": "hidden" });
                }
            });
            $default.click(function () {
                $multiple_choice.find("input").attr("disabled", $(this).is(":checked"));
                $check_all.prop('checked', false);
                if ($(this).is(":checked")) {
                    $.each(columuArr, (g, j) => {
                        if (
                            j[0] !== "ImpliedVolatility" &&
                            j[0] !== "TimetoMaturity" &&
                            j[0] !== "Delta" &&
                            j[0] !== "Theta" &&
                            j[0] !== "Moneyness"
                        ) {
                            j[3] = true;
                            //打勾
                            $multiple_choice
                                .find("input")
                                .eq(g)
                                .prop("checked", true);
                            if (g > 11) {
                                g = g - 12;
                            }
                            // 头部
                            $form
                                .find(".h2 #" + j[1])
                                .eq(g)
                                .addClass(j[1]);
                            // 标题
                            $form
                                .find(".h2 #" + j[1])
                                .eq(g)
                                .show();
                            // 内容
                            $.each($(".form_c"), function (n) {
                                $(".form_c")
                                    .eq(n)
                                    .find("." + j[2])
                                    .eq(g)
                                    .show();
                            });
                        } else {
                            switch (j[0]) {
                                case "ImpliedVolatility":
                                    j[3] = false;
                                    // 头部
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .removeClass(j[1]);
                                    // 标题
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .hide();
                                    // 内容
                                    $.each($(".form_c"), function (i) {
                                        $(".form_c")
                                            .eq(i)
                                            .find("." + j[2])
                                            .eq(g)
                                            .hide();
                                    });
                                    //去钩
                                    $multiple_choice
                                        .find("input")
                                        .eq(g)
                                        .prop("checked", false);
                                    break;
                                case "TimetoMaturity":
                                    j[3] = false;
                                    // 头部
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .removeClass(j[1]);
                                    // 标题
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .hide();
                                    // 内容
                                    $.each($(".form_c"), function (i) {
                                        $(".form_c")
                                            .eq(i)
                                            .find("." + j[2])
                                            .eq(g)
                                            .hide();
                                    });
                                    //去钩
                                    $multiple_choice
                                        .find("input")
                                        .eq(g)
                                        .prop("checked", false);
                                    break;
                                case "Delta":
                                    j[3] = false;
                                    // 头部
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .removeClass(j[1]);
                                    // 标题
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .hide();
                                    // 内容
                                    $.each($(".form_c"), function (i) {
                                        $(".form_c")
                                            .eq(i)
                                            .find("." + j[2])
                                            .eq(g)
                                            .hide();
                                    });
                                    //去钩
                                    $multiple_choice
                                        .find("input")
                                        .eq(g)
                                        .prop("checked", false);
                                    break;
                                // case "Premium":
                                //   j[3] = false;
                                //   // 头部
                                //   $form
                                //     .find(".h2 #" + j[1])
                                //     .eq(g)
                                //     .removeClass(j[1]);
                                //   // 标题
                                //   $form
                                //     .find(".h2 #" + j[1])
                                //     .eq(g)
                                //     .hide();
                                //   // 内容
                                //   $.each($(".form_c"), function (i) {
                                //     $(".form_c")
                                //       .eq(i)
                                //       .find("." + j[2])
                                //       .eq(g)
                                //       .hide();
                                //   });
                                //   //去钩
                                //   $multiple_choice
                                //     .find("input")
                                //     .eq(g)
                                //     .prop("checked", false);
                                //   break;
                                case "Theta":
                                    j[3] = false;
                                    // 头部
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .removeClass(j[1]);
                                    // 标题
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .hide();
                                    // 内容
                                    $.each($(".form_c"), function (i) {
                                        $(".form_c")
                                            .eq(i)
                                            .find("." + j[2])
                                            .eq(g)
                                            .hide();
                                    });
                                    //去钩
                                    $multiple_choice
                                        .find("input")
                                        .eq(g)
                                        .prop("checked", false);
                                    break;
                                case "Moneyness":
                                    j[3] = false;
                                    // 头部
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .removeClass(j[1]);
                                    // 标题
                                    $form
                                        .find(".h2 #" + j[1])
                                        .eq(g)
                                        .hide();
                                    // 内容
                                    $.each($(".form_c"), function (i) {
                                        $(".form_c")
                                            .eq(i)
                                            .find("." + j[2])
                                            .eq(g)
                                            .hide();
                                    });
                                    //去钩
                                    $multiple_choice
                                        .find("input")
                                        .eq(g)
                                        .prop("checked", false);
                                    break;
                            }
                        }
                    });
                    localStorage.setItem("recordArr", JSON.stringify(columuArr));
                    then.formHead();
                }
                then.FormdynamicChange();
            });
            // 单选
            $multiple_choice.click(function () {
                if (!$default.is(":checked")) {
                    var checked = $(this)
                        .find("input")
                        .is(":checked");
                    var multipleName = $(this)
                        .text()
                        .replace(/\s*/g, "");
                    $.each(columuArr, (v, i) => {
                        if (checked) {
                            switch (multipleName) {
                                case i[0]:
                                    i[3] = true;
                                    localStorage.setItem("recordArr", JSON.stringify(columuArr));
                                    if (v > 11) {
                                        v = v - 12;
                                    }
                                    // 头部
                                    $form
                                        .find(".h2 #" + i[1])
                                        .eq(v)
                                        .addClass(i[1]);
                                    // 标题
                                    $form
                                        .find(".h2 #" + i[1])
                                        .eq(v)
                                        .show();
                                    // 内容
                                    // $form.find('.form_c #' + i[2]).eq(v).show()
                                    for (var n = 0; n < $(".form_c").length; n++) {
                                        $(".form_c")
                                            .eq(n)
                                            .find("." + i[2])
                                            .eq(v)
                                            .show();
                                    }
                                    break;
                            }
                        } else {
                            switch (multipleName) {
                                case i[0]:
                                    i[3] = false;
                                    localStorage.setItem("recordArr", JSON.stringify(columuArr));
                                    if (v > 11) {
                                        v = v - 12;
                                    }
                                    // 头部
                                    $form
                                        .find(".h2 #" + i[1])
                                        .eq(v)
                                        .removeClass(i[1]);
                                    // 标题
                                    $form
                                        .find(".h2 #" + i[1])
                                        .eq(v)
                                        .hide();
                                    // 内容
                                    // $form.find('.form_c #' + i[2]).eq(v).hide()
                                    for (var n = 0; n < $(".form_c").length; n++) {
                                        $(".form_c")
                                            .eq(n)
                                            .find("." + i[2])
                                            .eq(v)
                                            .hide();
                                    }
                                    break;
                            }
                        }
                    });
                    then.FormdynamicChange();
                    then.formHead();
                    if ($("#searchForm").outerWidth(true) > 1283) {
                        $(".search-form-box").css({ "overflow-x": "scroll" });
                    } else {
                        $(".search-form-box").css({ "overflow-x": "hidden" });
                    }
                }
            });

            if (!this.estimate) {
                var num = 0;
                recordArr = JSON.parse(localStorage.getItem("recordArr"));
                $.each(recordArr, function (index, value) {
                    if (value[3]) {
                        num++;
                        if (index > 11) {
                            //加钩
                            $multiple_choice
                                .find("input")
                                .eq(index)
                                .prop("checked", true);
                            // 头部
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index - 12)
                                .addClass(value[1]);
                            // 标题
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index - 12)
                                .show();
                            // 内容
                            for (var n = 0; n < $(".form_c").length; n++) {
                                $(".form_c")
                                    .eq(n)
                                    .find("." + value[2])
                                    .eq(index - 12)
                                    .show();
                            }
                        } else {
                            //加钩
                            $multiple_choice
                                .find("input")
                                .eq(index)
                                .prop("checked", true);
                            // 头部
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index)
                                .addClass(value[1]);
                            // 标题
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index)
                                .show();
                            // 内容
                            for (var n = 0; n < $(".form_c").length; n++) {
                                $(".form_c")
                                    .eq(n)
                                    .find("." + value[2])
                                    .eq(index)
                                    .show();
                            }
                        }
                    } else {
                        if (index > 11) {
                            //去钩
                            $multiple_choice
                                .find("input")
                                .eq(index)
                                .prop("checked", false);
                            // 头部
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index - 12)
                                .removeClass(value[1]);
                            // 标题
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index - 12)
                                .hide();
                            // 内容
                            for (var n = 0; n < $(".form_c").length; n++) {
                                $(".form_c")
                                    .eq(n)
                                    .find("." + value[2])
                                    .eq(index - 12)
                                    .hide();
                            }
                        } else {
                            //去钩
                            $multiple_choice
                                .find("input")
                                .eq(index)
                                .prop("checked", false);
                            // 头部
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index)
                                .removeClass(value[1]);
                            // 标题
                            $form
                                .find(".h2 #" + value[1])
                                .eq(index)
                                .hide();
                            // 内容
                            for (var n = 0; n < $(".form_c").length; n++) {
                                $(".form_c")
                                    .eq(n)
                                    .find("." + value[2])
                                    .eq(index)
                                    .hide();
                            }
                        }
                    }
                });
                if (num === 18) {
                    $(".search-column-selection-box-h-c").prop("checked", true);
                } else if (num === 0) {
                    $(".search-column-selection-box-h-c").prop("checked", false);
                }
                this.formHead();
                then.FormdynamicChange();
            }
        },
        formData: function (data) {
            var formArr = [];
            for (var i = 0, max = data.length; i < max; i++) {
                // var underlying_bid = this.$js.spaceData(data[i].underlying_bid);
                // if (underlying_bid !== "N/A") {
                //   underlying_bid = this.$js.formatNumber(underlying_bid, 3, 0);
                // }
                var maturity = this.$js.spaceData(data[i].maturity);
                if (maturity !== "N/A") {
                    maturity = this.transitionData(
                        this.$js.changeToLocalTime(this.$js.formatDetailDate(maturity))
                    );
                }
                var sensitivity = data[i].sensitivity;
                if (sensitivity !== "N/A") {
                    sensitivity = this.$js.formatNumber(sensitivity, 1, 0);
                }
                var effectiveGearing = this.$js.spaceData(data[i].effectiveGearing);
                if (effectiveGearing !== "N/A") {
                    effectiveGearing = this.$js.formatNumber(effectiveGearing, 1, 0);
                }
                // var impliedVolalitiy = this.$js.formatNumber(data[i].impliedVolalitiy, 1, 0)
                // if (impliedVolalitiy == 0) {
                //     impliedVolalitiy = 0
                // }
                var impliedVolalitiy = this.$js.spaceData(data[i].impliedVolalitiy);
                if (impliedVolalitiy !== "N/A") {
                    impliedVolalitiy = this.$js.formatNumber(impliedVolalitiy, 1, 0);
                }
                var priceChange = this.$js.formatNumber(data[i].priceChange, 1, 0);
                if (priceChange > 0) {
                    priceChange = this.$js.changeRate(priceChange);
                } else if (priceChange == 0) {
                    priceChange = 0;
                }
                var theta = this.$js.spaceData(data[i].theta);
                if (theta !== "N/A") {
                    if (Math.abs(Number(data[i].theta)) < 0.0001) {
                        theta = "-0.0000";
                    } else {
                        theta = this.$js.formatNumber(data[i].theta, 4, 0);
                    }
                }
                var premium = this.$js.spaceData(data[i].premium);
                if (premium !== "N/A") {
                    premium = this.$js.formatNumber(premium, 1, 0);
                }
                formArr[i] = [
                    "",
                    this.$js.spaceData(data[i].dsply_nmll),
                    this.$js.rewriteIssueName(this.$js.spaceData(data[i].issuer)),
                    this.$js.curreryRetrun(data[i].underlying_curr) +
                    " " +
                    this.$js.spaceData(data[i].underlying_bid),
                    this.$js.curreryRetrun(data[i].underlying_curr) +
                    " " +
                    this.$js.formatNumberNo(data[i].exercisePrice, 4),
                    this.$js.formatNumberNo(data[i].conv_ratio, 4),
                    maturity,
                    sensitivity,
                    effectiveGearing,
                    impliedVolalitiy,
                    this.$js.spaceData(data[i].TimeToMaturity),
                    this.$js.spaceData(data[i].delta),
                    premium,
                    theta,
                    this.$js.spaceData(data[i].moneyness_c),
                    this.$js.formatNumber(this.$js.clearRM(data[i].bidVolume), 0, 1),
                    this.$js.formatNumber(data[i].bidPrice, 3, 0),
                    this.$js.formatNumber(data[i].askPrice, 3, 0),
                    this.$js.formatNumber(this.$js.clearRM(data[i].askVolume), 0, 1),
                    this.$js.formatNumber(this.$js.clearRM(data[i].tradeVolume), 0, 1),
                    priceChange,
                    data[i].soldout,
                    data[i].ric
                ];
            }
            this.assignment(formArr);
        },
        // 时间转换
        transitionData: function (time) {
            var MMM = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            return (
                time.getDate() +
                " " +
                MMM[time.getMonth()] +
                " " +
                time
                    .getFullYear()
                    .toString()
                    .substring(4 - 2, 4)
            );
        },
        // 表格动态变化
        FormdynamicChange: function () {
            let _this = this;
            let formBoxW = $(".search-form-box").width();
            let formW = $("#searchForm").width();
            let formT = $("#SFormBox").offset().top;
            let formArrows = $("#warransearch").find(".form_arrows");
            let scrollLeft = $(".search-form-box").scrollLeft();
            let titleTextH = $("#warransearch")
                .find(".title_text")
                .outerHeight(true);
            let selectH = $(".search-select-show").outerHeight(true);
            // 复制
            // warrntName
            setTimeout(function () {
                $(".warrntName").empty();
                $(".warrntName").append(
                    $(".search-form-box")
                        .find("#searchForm")
                        .clone()
                );
                $(".warrntName")
                    .find("table")
                    .width(
                        $("#SFormBox")
                            .find("table")
                            .outerWidth(true)
                    );
                // $(".warrntName").width(
                //     $("#searchForm")
                //         .find(".h2")
                //         .find("th")
                //         .eq(0)
                //         .outerWidth(true) + $("#searchForm")
                //             .find(".h2")
                //             .find("th")
                //             .eq(1)
                //             .outerWidth(true)
                // );
                $(".warrntName").width(
                    $("#searchForm")
                        .find(".h2")
                        .find("th")
                        .eq(0)
                        .outerWidth(true)
                );
                $(".warrntName").css({
                    top: formT + "px"
                });
            }, 10);

            //warrntNameTop
            setTimeout(function () {
                $(".warrntNameTop").empty();
                $(".warrntNameTop").append(
                    $(".search-form-box")
                        .find("#searchForm")
                        .clone()
                );
                $(".warrntNameTop")
                    .find("table")
                    .width(
                        $("#SFormBox")
                            .find("table")
                            .outerWidth(true)
                    );
                // $(".warrntNameTop").width(
                //     $("#searchForm")
                //         .find(".h2")
                //         .find("th")
                //         .eq(0)
                //         .outerWidth(true) +  $("#searchForm")
                //         .find(".h2")
                //         .find("th")
                //         .eq(1)
                //         .outerWidth(true)
                // );

                $(".warrntNameTop").width(
                    $("#searchForm")
                        .find(".h2")
                        .find("th")
                        .eq(0)
                        .outerWidth(true)
                );
                $(".warrntNameTop").height(
                    $("#SFormBox")
                        .find("thead")
                        .outerHeight(true)
                );
                $(".warrntNameTop").css({
                    top: $(".header-box").innerHeight() + "px"
                });
            }, 10);

            // theadClone
            setTimeout(function () {
                $(".theadClone").empty();
                $(".theadClone").height(
                    $("#SFormBox")
                        .find("thead")
                        .outerHeight(true)
                );
                $(".theadClone").append(
                    $(".search-form-box")
                        .find("#searchForm")
                        .clone()
                );
                $(".theadClone")
                    .find("table")
                    .width(
                        $("#SFormBox")
                            .find("table")
                            .outerWidth(true)
                    );
                $(".theadClone").width(formBoxW);
                $(".theadClone").css({
                    top: $(".header-box").innerHeight() + "px"
                });
            }, 10);

            if ($(window).scrollTop() > formT) {
                $(".theadClone").show();
                $(".warrntNameTop").show();
            } else {
                $(".theadClone").hide();
                $(".warrntNameTop").hide();
            }
            $(".f_right").css({
                right: (($(window).width() - formBoxW) / 2) - 25 + 'px'
            });
            $(".f_left").css({
                left: (($(window).width() - formBoxW) / 2) - 25 + 'px'
            });
            // 三角箭头
            if ($(document).scrollTop() >= $("#SFormBox").outerHeight(true) - 500) {
                // 三角箭头
                if (_this.MoreFilterT) {
                    formArrows.css({
                        top: formT - 600 + "px"
                    });
                } else {
                    formArrows.css({
                        top: formT - 200 + "px"
                    });
                }
            } else {
                // 三角箭头
                if (_this.MoreFilterT) {
                    formArrows.css({
                        top: formT - 400 + "px"
                    });
                } else {
                    formArrows.css({
                        top: formT + "px"
                    });
                }
            }

            if (formBoxW < formW) {
                $(".form_arrows").show();
            } else {
                $(".form_arrows").hide();
            }
            $(".f_right").click(function () {
                scrollLeft = $(".search-form-box").scrollLeft();
                $(".search-form-box").scrollLeft(scrollLeft + 10);
                $(".theadClone").scrollLeft(scrollLeft + 10);
                $(".warrntName").show();
                if ($(window).scrollTop() > formT) {
                    $(".warrntNameTop").show();
                } else {
                    $(".warrntNameTop").hide();
                }
            });
            $(".f_left").click(function () {
                scrollLeft = $(".search-form-box").scrollLeft();
                $(".search-form-box").scrollLeft(scrollLeft - 10);
                $(".theadClone").scrollLeft(scrollLeft - 10);
                if (scrollLeft <= 0) {
                    $(".warrntName").hide();
                }
            });

            // 监听屏幕
            $(window).resize(() => {
                formBoxW = $(".search-form-box").width();
                formW = $("#searchForm").width();
                formT = $("#SFormBox").offset().top;
                // warrntName
                $(".warrntName").width(
                    $("#searchForm")
                        .find(".h2")
                        .find("th")
                        .eq(0)
                        .outerWidth(true)
                );
                $(".warrntName")
                    .find("table")
                    .width(
                        $("#SFormBox")
                            .find("table")
                            .outerWidth(true)
                    );
                $(".warrntName").css({
                    top: formT + "px"
                });
                //warrntNameTop
                $(".warrntNameTop").width(
                    $("#searchForm")
                        .find(".h2")
                        .find("th")
                        .eq(0)
                        .outerWidth(true)
                );
                $(".warrntNameTop")
                    .find("table")
                    .width(
                        $("#SFormBox")
                            .find("table")
                            .outerWidth(true)
                    );
                $(".warrntNameTop").css({
                    top: $(".header-box").innerHeight() + "px"
                });
                // theadClone
                $(".theadClone").width(formBoxW);
                $(".theadClone")
                    .find("table")
                    .width(
                        $("#SFormBox")
                            .find("table")
                            .outerWidth(true)
                    );
                $(".theadClone").css({
                    top: $(".header-box").innerHeight() + "px"
                });
                // 三角箭头
                titleTextH = $("#warransearch")
                    .find(".title_text")
                    .outerHeight(true);
                selectH = $(".search-select-show").outerHeight(true);
                if (formBoxW < formW) {
                    $(".form_arrows").show();
                } else {
                    $(".form_arrows").hide();
                }
                $(".f_right").css({
                    right: (($(window).width() - formBoxW) / 2) - 25 + 'px'
                });
                $(".f_left").css({
                    left: (($(window).width() - formBoxW) / 2) - 25 + 'px'
                });
                if ($(document).scrollTop() >= $("#SFormBox").outerHeight(true) - 500) {
                    // 三角箭头
                    if (_this.MoreFilterT) {
                        formArrows.css({
                            top: formT - 600 + "px"
                        });
                    } else {
                        formArrows.css({
                            top: formT - 200 + "px"
                        });
                    }
                } else {
                    // 三角箭头
                    if (_this.MoreFilterT) {
                        formArrows.css({
                            top: formT - 400 + "px"
                        });
                    } else {
                        formArrows.css({
                            top: formT + "px"
                        });
                    }
                }
            });
            // 监听滚动条
            $(window).scroll(function () {
                formT = $("#SFormBox").offset().top;
                if ($(this).scrollTop() > formT) {
                    $(".theadClone").show();
                    $(".warrntNameTop").show();
                } else {
                    $(".theadClone").hide();
                    $(".warrntNameTop").hide();
                }
                $(".warrntName").css({
                    top: formT + "px"
                });
                $(".warrntNameTop").css({
                    top: $(".header-box").innerHeight() + "px"
                });
                $(".theadClone").css({
                    top: $(".header-box").innerHeight() + "px"
                });
                if ($(document).scrollTop() >= $("#SFormBox").outerHeight(true) - 500) {
                    // 三角箭头
                    if (_this.MoreFilterT) {
                        formArrows.css({
                            top: formT - 600 + "px"
                        });
                    } else {
                        formArrows.css({
                            top: formT - 200 + "px"
                        });
                    }
                } else {
                    // 三角箭头
                    if (_this.MoreFilterT) {
                        formArrows.css({
                            top: formT - 400 + "px"
                        });
                    } else {
                        formArrows.css({
                            top: formT + "px"
                        });
                    }
                }
            });
            this.$js.ScreenMonitor();
        },
        formHead: function () {
            $("#SFormBox")
                .find("#searchForm")
                .find(".h1")
                .empty();
            var formh1 = "";
            formh1 +=
                '<th colspan = "' +
                $("#SFormBox")
                    .find("#searchForm")
                    .find(".h2")
                    .find(".h2_one").length +
                '" style = "background-color: #5bc2e7" >WARRANT</th>';
            if (
                $("#SFormBox")
                    .find("#searchForm")
                    .find(".h2")
                    .find(".h2_two").length !== 0
            ) {
                formh1 +=
                    '<th colspan="' +
                    $("#SFormBox")
                        .find("#searchForm")
                        .find(".h2")
                        .find(".h2_two").length +
                    '" style="background-color: #0067b1">MARKET</th>';
            }
            $("#SFormBox")
                .find("#searchForm")
                .find(".h1")
                .append(formh1);
        },
        assignment: function (data) {
            var formText = "";
            var soldout = "";
            $(".search-form-box tbody").empty();
            for (var i = 0, max = data.length; i < max; i++) {
                if (Number(data[i][data[i].length - 2]) !== 0) {
                    soldout =
                        "<br><span style='display: inline-block; color: red; font-size: 10px; border: 1px solid; padding: 2px 4px; white-space: nowrap;'>Low On Inventory</span>";
                } else {
                    soldout = "";
                }
                if (data[i][1] !== "RHB") {
                    formText += '<tr class="form_c">';
                    $.each(data[i], function (j, v) {
                        if (j <= 14) {
                            if (j < 3) {
                                if (j === 1) {
                                    if (data[i][data[i].length - 1] !== " ") {
                                        formText +=
                                            '<td class="search-td-h"><a onclick="getRicToDetails(\'' +
                                            data[i][data[i].length - 1] +
                                            "' ," +
                                            " '" +
                                            data[i][1] +
                                            "')\">" +
                                            v +
                                            "</a>" +
                                            soldout +
                                            "</td>";
                                    } else {
                                        formText +=
                                            '<td class="search-td-h"> <a>' +
                                            v +
                                            "</a>" +
                                            soldout +
                                            "</td>";
                                    }
                                } else {
                                    if (j === 0) {
                                        if (data[i][data[i].length - 1] !== " ") {
                                            // formText +=
                                            //     '<td class="search-td-h"><a onclick="getRicToMatrix(\'' +
                                            //     data[i][data[i].length - 1] +
                                            //     "' ," +
                                            //     " '" +
                                            //     data[i][1] +
                                            //     "')\"> <i class='bi bi-arrow-right-circle'></i></a></td>";
                                        } else {
                                            formText +=
                                                "<td class='search-td-h'> <a><i class='bi bi-arrow-right-circle'></i></a></td>";
                                        }
                                    } else {
                                        formText += "<td>" + v + "</td>";
                                    }

                                }

                            } else {
                                formText += '<td class="c_one_1">' + v + "</td>";
                            }
                        } else {
                            if (j === 20) {
                                if (v > 0) {
                                    formText +=
                                        '<td class="c_two_1" style="color: rgb(0, 153, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v < 0) {
                                    formText +=
                                        '<td class="c_two_1" style="color: rgb(255, 0, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v == 0) {
                                    formText += '<td class="c_two_1">' + v + "</td>";
                                }
                            } else {
                                formText += '<td class="c_two_1">' + v + "</td>";
                            }
                        }
                    });
                    formText += "</tr>";
                } else {
                    formText += '<tr class="form_c">';
                    $.each(data[i], function (j, v) {
                        if (j <= 14) {
                            if (j < 3) {
                                if (j === 1) {
                                    if (data[i][data[i].length - 1] !== " ") {
                                        formText +=
                                            '<td class="search-td-h"><a onclick="getRicToDetails(\'' +
                                            data[i][data[i].length - 1] +
                                            "'," +
                                            " '" +
                                            data[i][1] +
                                            "')\">" +
                                            v +
                                            "</a>" +
                                            soldout +
                                            "</td>";
                                    } else {
                                        formText +=
                                            '<td class="search-td-h"> <a>' +
                                            v +
                                            "</a>" +
                                            soldout +
                                            "</td>";
                                    }
                                } else {
                                    if (j === 0) {
                                        if (data[i][data[i].length - 1] !== " ") {
                                            // formText +=
                                            //     '<td class="search-td-h"><a onclick="getRicToMatrix(\'' +
                                            //     data[i][data[i].length - 1] +
                                            //     "' ," +
                                            //     " '" +
                                            //     data[i][1] +
                                            //     "')\"> <i class='bi bi-arrow-right-circle'></i></a></td>";
                                        } else {
                                            formText +=
                                                "<td class='search-td-h'> <a><i class='bi bi-arrow-right-circle'></i></a></td>";
                                        }
                                    } else {
                                        formText += "<td>" + v + "</td>";
                                    }
                                }
                            } else {
                                formText += '<td class="c_one_1 tdRhb">' + v + "</td>";
                            }
                        } else {
                            if (j === 20) {
                                if (v > 0) {
                                    formText +=
                                        '<td class="c_two_1 tdRhb" style="color: rgb(0, 153, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v < 0) {
                                    formText +=
                                        '<td class="c_two_1 tdRhb" style="color: rgb(255, 0, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v == 0) {
                                    formText += '<td class="c_two_1 tdRhb">' + v + "</td>";
                                }
                            } else {
                                formText += '<td class="c_two_1 tdRhb">' + v + "</td>";
                            }
                        }
                    });
                    formText += "</tr>";
                }
            }
            $(".search-form-box tbody").append(formText);
            this.showForm();
            this.FormdynamicChange();
            let _this = this;
            window.getRicToDetails = function (ric, dsplay_nmll) {
                localStorage.setItem("detailsRic", ric);
                let routes = _this.$router.resolve({
                    path: "/my/WarrantDetails",
                    query: { dsplay_nmll: dsplay_nmll }
                });
                location.href = routes.href;
            };
            window.getRicToMatrix = function (ric, dsplay_nmll) {
                localStorage.setItem("priceMatrix", ric);
                let routes = _this.$router.resolve({
                    path: "/my/WarrantMatrix",
                    query: { dsplay_nmll: dsplay_nmll }
                });
                location.href = routes.href;
            };
        },
        printTable: function () {
            /*打印table*/
            $("#tableToExcel").click(() => {
                var otherdata = "";
                for (
                    var i = 0;
                    i <
                    $("#searchForm>thead>tr")
                        .eq(1)
                        .find("th:visible").length;
                    i++
                ) {
                    otherdata += $("#searchForm>thead>tr")
                        .eq(1)
                        .find("th:visible")
                        .eq(i)
                        .text()
                        .trim();
                    if (
                        i + 1!=
                        $("#searchForm>thead>tr")
                            .eq(1)
                            .find("th:visible").length
                    ) {
                        otherdata += ",";
                    }
                }
                this.export_table_ws("#formData", otherdata, "Warrant Search");
            });
        },
        export_table_ws: function (selector, otherdata, name) {
            if (document.querySelectorAll(selector).length == 0) {
                alert('Selector "' + selector + '" not exists!');
                return false;
            }
            this.$js.export_csv(
                otherdata + "\n" + this.get_table_data_ws(selector),
                name
            );
        },
        get_table_data_ws: function (selector) {
            var data = "";
            for (var i = 0; i < $(selector).find("tr:visible").length; i++) {
                for (
                    var j = 0;
                    j <
                    $(selector)
                        .find("tr:visible")
                        .eq(i)
                        .find("td:visible").length;
                    j++
                ) {
                    data += $(selector)
                        .find("tr:visible")
                        .eq(i)
                        .find("td:visible")
                        .eq(j)
                        .text()
                        .trim()
                        .split(",")
                        .join("");
                    if (
                        j + 1 !=
                        $(selector)
                            .find("tr:visible")
                            .eq(i)
                            .find("td:visible").length
                    ) {
                        data += ",";
                    }
                }

                data += "\n";
            }
            return data;
        }
    }
};
